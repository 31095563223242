import React from 'react';
import styled from '@emotion/styled';
import { Container, Row, Col } from 'react-bootstrap';
import { keyframes } from '@emotion/react';
import { Typography } from '@mui/material';
import { ReactComponent as YouTubeIcon } from '../../assets/images/youtube-svgrepo-com.svg';
import { ReactComponent as TelegramIcon } from '../../assets/images/telegram-svgrepo-com.svg';
import {Link} from "react-router-dom";


const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const FooterContainer = styled.footer`
  position: relative;
  padding: 50px 0;
  background: linear-gradient(45deg, #ff6f61, #de1d23);  // Pleasant and warm gradient
  color: #fff;
  animation: ${fadeIn} 1.5s ease-in-out;
  overflow: hidden;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  // Center the items
  text-align: center;  // Center text within items
  z-index: 1;
`;
const FooterRow = styled(Row)`
  align-items: center;
`;

const FooterCol = styled(Col)`
  text-align: center;
  margin-bottom: 20px;

  &:nth-of-type(2) {
    text-align: center;
  }
`;

const FooterLogo = styled.div`
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(45deg, #ff9a9e, #fad0c4);  // Softer, pleasant gradient
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;  // Add margin to separate from the logo
`;

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #ffd1a1;  // Light color for hover effect
  }
`;

const FooterLinkes = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #ffd1a1;  // Light color for hover effect
  }
`;
const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;

  svg {
    width: 35px;
    height: 35px;
    fill: #fff;  // Set a default white color for the icons
    transition: fill 0.3s ease;

    &:hover {
      fill: #ffd1a1;  // Matching hover color for icons
    }

    &:nth-of-type(1) {
      fill: #FF0000;  // YouTube red to contrast the background or a lighter shade if preferred
      background-color: #fff;  // Optional: add a white circle background
      border-radius: 50%;  // Make the background a circle
      padding: 5px;  // Ensure the icon has enough space inside the circle
    }
  }
`;

// Or use a different approach to add a circular background
const YouTubeIconStyled = styled(YouTubeIcon)`
  background-color: #fff;  // White background for better contrast
  border-radius: 50%;
  padding: 5px;
  width: 35px;
  height: 35px;
  fill: #FF0000;  // YouTube red or a suitable contrasting color
  transition: fill 0.3s ease;

  &:hover {
    fill: #ffd1a1;  // Adjust hover effect if necessary
  }
`;

const Footer = () => {
  return (
      <FooterContainer>
        <Container>
          <FooterContent>
            <FooterLogo>ArbitrageMirage</FooterLogo>
            <FooterLinks>
              <FooterLink
                  to="/#features"
              >
                Benefits
              </FooterLink>
              <FooterLink to="/#">Videos</FooterLink>
              <FooterLinkes href="/#">Scanner</FooterLinkes>
              <FooterLink
                  to="/#pricing-section"
              >
                Pricing
              </FooterLink>
              <FooterLinkes href="https://t.me/arbitragemirage_support">Support</FooterLinkes>
            </FooterLinks>
            <SocialIcons>
              <YouTubeIconStyled />  {/* Using the styled component */}
              <TelegramIcon />
            </SocialIcons>
              <Typography variant="body2" align="center" style={{ marginTop: '20px', color: '#dcdcdc' }}>
                  © ArbitrageMirage 2024 | <FooterLinkes href="/privacy_policy.pdf" download>Privacy Policy</FooterLinkes>
              </Typography>
          </FooterContent>
        </Container>
      </FooterContainer>
  );
};

export default Footer;