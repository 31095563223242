export const IS_DEVELOPMENT =
  process.env.NODE_ENV === 'development' ||
  window.origin.startsWith('http://localhost') ||
  window.origin.startsWith('http://192.168');

export const APP_CONTAINER_ID = 'appContainerId';

export const CLIENT_ID = 'RealtorPortal';
export const REALTOR_ID = 'realtorId';

export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_TOKEN_EXPIRATION_DATE = 'accessTokenExpirationDate';

export const REFRESH_TOKEN = 'refreshToken';
export const REFRESH_TOKEN_EXPIRATION_DATE = 'refreshTokenExpirationDate';

// @ts-ignore
export const IS_SAFARI = !!window?.safari;
export const RouteLocal = 'https://arbitragemirage.trade';
// http://localhost:8000
//http://127.0.0.1:8080
// https://arbitragemirage.trade
//export const RouteLocal = 'https://arbitrage-demo.fly.dev';
// http://127.0.0.1:8080
export const RouteProd = ''