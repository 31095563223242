import { useState, useEffect } from 'react';


const useDealLifespan = (newDeals: any[], updateInterval: number = 15000) => {
    const [lifespanData, setLifespanData] = useState<{ [dealId: string]: number }>({});

    // Восстановление времени жизни из localStorage при загрузке страницы
    useEffect(() => {
        const savedLifespanData = JSON.parse(localStorage.getItem('dealLifespan') || '{}');
        setLifespanData(savedLifespanData || {});

    }, []);

    // Сохранение времени жизни в localStorage
    useEffect(() => {
        if (Object.keys(lifespanData).length > 0) {
            localStorage.setItem('dealLifespan', JSON.stringify(lifespanData));
        }
    }, [lifespanData]);

    // Обновление данных по новым сделкам
    useEffect(() => {
        if (newDeals.length === 0) return;

        const newLifespanData = { ...lifespanData };
        let isUpdated = false;

        // Добавляем новые сделки или проверяем существующие
        newDeals.forEach((deal) => {
            const dealId = deal._id; // Убедитесь, что используете правильный ID
            if (!newLifespanData[dealId]) {
                // Если сделки еще нет в сохраненных, добавляем время её создания
                newLifespanData[dealId] = Date.now();
                isUpdated = true;
            }
        });

        // Удаление сделок, которых больше нет
        Object.keys(newLifespanData).forEach((dealId) => {
            if (!newDeals.some((deal) => deal._id === dealId)) {

                delete newLifespanData[dealId];
                isUpdated = true;
            }
        });

        // Обновляем состояние только если произошли изменения
        if (isUpdated) {
            setLifespanData(newLifespanData);
        }
    }, [newDeals, lifespanData]);

    // Вычисляем время жизни для каждой сделки
    const dealLifespans = newDeals.map(deal => {
        const dealId = deal._id;
        const startTime = lifespanData[dealId];
// ts-ignore
        const formatTime = (seconds: any) => {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;

            return `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${secs}s`;
        };

// Пример использования в вашем компоненте:
        const lifespan = startTime ? Math.floor((Date.now() - startTime) / 1000) : 0;
        const formattedLifespan = formatTime(lifespan);


        return {
            ...deal,
            lifespan: formattedLifespan
        };
    });

    return dealLifespans;
};

export default useDealLifespan;
