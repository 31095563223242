import React, { useState } from 'react';
import { Typography, Card } from '@mui/material';
import { Question, Answer, FAQCard, ToggleIcon, FAQSection, FAQTitle } from '../Main.styles';

const FAQ: React.FC = () => {
    const [expanded, setExpanded] = useState<number | null>(null);

    const toggleFAQ = (index: number) => {
        setExpanded(expanded === index ? null : index);
    };

    const faqs = [
        {
            "question": "How many exchanges are monitored by the scanner?",
            "answer": "Our arbitrage scanner supports 17 exchanges, while the funding scanner supports 12 exchanges."
        },
        { question: "How often do spreads appear and how long do they last?", answer: "Spreads are always present and typically last for 10-20 minutes." },
        { question: "What is the minimum amount needed to start trading?", answer: "You can start with any amount, but for more effective trading and to cover transfer fees, it is recommended to have a deposit of at least 300 USDT." },
        { question: "What is the average profit?", answer: "Profit in arbitrage depends on the market, your skills, and the time you spend trading. On average, you can earn 1-5% per day on inter-exchange arbitrage. In highly volatile markets, profits can exceed 10% within a few hours." },
        // { question: "What about referrals?", answer: "You can earn a percentage of the subscription cost for each user you refer. Detailed information is available in the profile." },
        {
            question: "Is there support available?",
            answer: (
                <>
                    For any questions, you can contact our{" "}
                    <a href="https://t.me/arbitragemirage_support" target="_blank" rel="noopener noreferrer">
                        administrator
                    </a>.
                </>
            )
        }
    ];

    return (
        <FAQSection>
            <FAQTitle>FAQs</FAQTitle>
            <div>
                {faqs.map((faq, index) => (
                    <FAQCard key={index}>
                        <Question onClick={() => toggleFAQ(index)}>
                            {faq.question}
                            <ToggleIcon className={expanded === index ? 'expanded' : 'collapsed'}>
                                ▼
                            </ToggleIcon>
                        </Question>
                        <Answer isExpanded={expanded === index}>{faq.answer}</Answer>
                    </FAQCard>
                ))}
            </div>
        </FAQSection>
    );
};

export default FAQ;
