import ApplicationLayout from 'components/ApplicationLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import ToastContainer from 'components/ToastContainer';

import { Navigate, Route, Routes } from 'react-router-dom';

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Main from 'pages/Main';
import AuthWrapper from "components/AuthWrapper";
import ArbitrageCoins from "pages/ArbitrageCoins";
import Funding from 'pages/Funding';
import Dashboard from "pages/Dashboard";
import Payment from 'pages/Payment';
import PricingSection from './pages/Main/components/PriceInfo'
import {useState} from "react";
import RequestPasswordReset from "pages/RequestReset";

function App() {
  const [isOpenSign, setIsOpenSign] = useState(false);

   function handleOpen () {
    setIsOpenSign(!isOpenSign)
  }
  // @ts-ignore
    return (
    <ErrorBoundary>
      <ApplicationLayout setIsOpenSign={handleOpen}>
      <ToastContainer />
      <Routes>
        <Route element={<Main isOpenSign={isOpenSign} setIsOpenSign={setIsOpenSign} />} path="/" />
        <Route element={<RequestPasswordReset/>} path="/request_password_reset" />
        <Route element={<AuthWrapper><Funding /></AuthWrapper>} path="/funding" />
        <Route element={<AuthWrapper><ArbitrageCoins /></AuthWrapper>} path="/arbitrage_coins" />
        <Route element={<AuthWrapper><Dashboard /></AuthWrapper>} path="/dashboard" />
        <Route element={<AuthWrapper><Payment /></AuthWrapper>} path="/payment" />
        <Route
          // TODO: need to think about improving redirect logic
          // prevent multiple redirecting after toggling isAuthenticated to true in Verification step
          element={<Navigate replace to={'/'} />}
          path="*"
        />
      </Routes>
      </ApplicationLayout>
    </ErrorBoundary>
  );
}

export default App;
